// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    & .overlay {
        display: grid;
        grid-template-rows: 1fr 6rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
        align-items: center;
        justify-content: center;

        & .content {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 2rem;
            max-width: 1100px;
            margin-top: 100px; /* Equal to header height */
            padding: 0 var(--padding-right) 0 var(--padding-left);
            color: #fff;

            & .primary-color {
                color: var(--color-primary);
            }

            & h1 {
                font-size: var(--font-size-banner-h1);
                font-weight: var(--font-weight-banner-h1);
                line-height: var(--line-height-banner-h1);
            }

            & p {
                font-size: 3rem;
                font-weight: 400;
                line-height: 1.5;
            }

            &-link {
                justify-self: start;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;

                & span {
                    margin-right: 10px;
                }
            }

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledOurServices = styled.div`
    #web-ontwerp {
        background-image: url(${props => !!props.webOntwerpImage.childImageSharp ? props.webOntwerpImage.childImageSharp.fluid.src : props.webOntwerpImage});
    }
    #webwinkels {
        background-image: url(${props => !!props.webwinkelImage.childImageSharp ? props.webwinkelImage.childImageSharp.fluid.src : props.webwinkelImage});
    }
    #web-applicaties {
        background-image: url(${props => !!props.webApplicatiesImage.childImageSharp ? props.webApplicatiesImage.childImageSharp.fluid.src : props.webApplicatiesImage});
    }
    #online-adverteren {
        background-image: url(${props => !!props.onlineAdverterenImage.childImageSharp ? props.onlineAdverterenImage.childImageSharp.fluid.src : props.onlineAdverterenImage});
    }

    & .row {
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;

        &--1 .content {
            clip-path: polygon(0 0, 75% 0, 50% 100%, 0% 100%);
            padding-right: 50%;
            background-color: var(--color-background-2);

            @media only screen and (max-width: 800px) {
                padding: var(--padding-right);
                padding-bottom: 50rem;
                clip-path: polygon(0 0,100% 0,100% calc(100% - 50rem),0 calc(100% - 40rem));
            }
        }

        &--2 .content {
            clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 50% 100%);
            padding-left: 50%;

            @media only screen and (max-width: 800px) {
                padding: var(--padding-right);
                padding-bottom: 50rem;
                clip-path: polygon(0 0,100% 0,100% calc(100% - 40rem),0 calc(100% - 50rem));

                &-link {
                    justify-self: end;
                }
            }
        }

        &--3 {
            & .content {
                background-color: var(--color-background-1);
                color: #fff;
                grid-template-columns: 75% 1fr;
                grid-row-gap: 1rem;

                @media only screen and (max-width: 950px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(3, min-content);
                    grid-row-gap: 5rem;
                }

                & h1 {
                    font-size: 5rem;
                    font-weight: var(--font-weight-body-h1);
                    line-height: var(--line-height-body-h1);
                }

                & h2 {
                    font-size: 3rem;
                    font-weight: 100;
                    line-height: var(--line-height-body-h2);
                    color: var(--color-primary);

                    @media only screen and (max-width: 950px) {
                        margin-top: -4rem;
                    }
                }

                &-link {
                    grid-column: 2 / 3;
                    grid-row: 1 / 3;
                    align-self: center;
                    justify-self: center;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    background-color: var(--color-primary);
                    border: 2px solid var(--color-primary);
                    border-radius: 0;
                    font-size: 2rem;
                    padding: 1rem 2rem;
                    color: #fff;
                    font-weight: 400;
                    text-decoration: none;

                    & span {
                        margin-right: 10px;
                    }

                    @media only screen and (max-width: 950px) {
                        grid-column: 1 / -1;
                        grid-row: 3 / 4;
                        justify-self: start;
                    }
                }

                &-link:link,
                &-link:visited {
                    color: currentColor;
                    text-decoration: none;
                    outline: 0;
                    transition: color .25s ease;
                }

                &-link:hover,
                &-link:focus {
                    text-decoration: none;
                    color: currentColor;
                    outline: 0;
                    transform: translateY(-3px);
                    box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
                }

                &-link:active {
                    text-decoration: none;
                    color: currentColor;
                    outline: 0;
                    transform: translateY(0);
                    box-shadow: none;
                }
            }
        }
    }

    & .content {
        display: grid;
        grid-row-gap: 3rem;
        background-color: #fff;
        padding: var(--padding-right);

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & p {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        &-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        &-link:link,
        &-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;


// Create component
const OurServicesPage = () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/onze-diensten/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            webOntwerpImage: file(relativePath: { eq: "onze-diensten/Web-ontwerp.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webwinkelImage: file(relativePath: { eq: "onze-diensten/Webwinkel.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webApplicatiesImage: file(relativePath: { eq: "onze-diensten/Web-applicaties.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            onlineAdverterenImage: file(relativePath: { eq: "onze-diensten/Online-ads-editted.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Handle scrolling down to below banner
    const handleScrollDown = () => {
        // Get banner height
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        
        // Scroll to place underneath banner
        window.scrollTo({
            top: bannerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Onze Diensten" />
            <Layout withBanner={true}>
                <StyledBanner
                    className="banner"
                    image={data.bannerImage}
                >
                    <div className="overlay">
                        <div className="content">
                            <h1>What we offer</h1>
                            <p>We <span className="primary-color">specialize</span> in web design, online stores, web applications, online advertising, digital marketing, content writing, top notch security, privacy, and optimal hosting for all of our <span className="primary-color">products and services</span>. Below you find more information about <span className="primary-color">our services</span>.</p>
                        </div>

                        <div className="scroll-down-button" onClick={handleScrollDown}>
                            <FontAwesomeIcon icon={faArrowDown} className="icon" />
                        </div>
                    </div>
                </StyledBanner>

                <StyledOurServices
                    webOntwerpImage={data.webOntwerpImage}    
                    webwinkelImage={data.webwinkelImage}    
                    webApplicatiesImage={data.webApplicatiesImage}    
                    onlineAdverterenImage={data.onlineAdverterenImage}    
                >
                    <div className="row row--1" id="web-ontwerp">
                        <div className="content">
                            <h1>Web Design</h1>

                            <p>All our products are custom designed according to the wishes of the customer. Everything is therefore possible at <span className="primary-color">boumedia</span>. Based on an orientation meeting, we take note of your wishes and we start designing your tailor-made website.</p>

                            <p>The right content ensures that your website comes to life. Think of professional photos, videos and strong text so that visitors to your website get a clear picture of your company.</p>

                            <p>Daarnaast ontwerpen wij uw website met de laatste technologie&euml;n en zorgen wij ervoor dat uw website goed tot zijn recht zal komen op de verschillende beeldschermen. Het mobiele verkeer zal voor het grootste aantal bezoekers op uw website zorgen, een responsive web ontwerp is daarom van essentieel belang.</p>

                            <p>In addition, we design your website with the latest technologies and we ensure that your website will look amazing on different screen sizes. Mobile traffic will generate the largest number of visitors to your website, thefore, a responsive web design is essential.</p>

                            <p>We work on your web design until you are 100% satisfied. Then we will proceed to develop your tailor-made website.</p>

                            <Link to="/contact" className="content-link">
                                <span>Please feel free to contact us</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--2" id="online-stores">
                        <div className="content">
                            <h1>Online Stores</h1>

                            <p>Online shopping increased more and more in recent years. When you sell physical products, you can hardly do without a webshop. The latest technologies regarding the development of a webshop are therefore increasingly accessible and ensure that a basic webshop can be realized very easily. This makes it increasingly attractive for start-ups to open a webshop.</p>

                            <p><span className="primary-color">Boumedia</span> heeft de afgelopen jaren verschillende webwinkels in diverse landen succesvol gerealiseerd. Veel van deze webwinkels hebben hun assortiment inmiddels flink uitgebreid. Door onze kennis in online marketing zorgen wij ervoor dat uw producten bij de juiste doelgroep naar voren komen.</p>

                            <p><span className="primary-color">Boumedia</span> has successfully realized various webshops in various countries in recent years. Many of these webshops have now expanded their range considerably. Thanks to our knowledge in online marketing, we ensure that your products reach the right target group.</p>

                            <p>Are you thinking of a webshop? Please feel free to contact us without obligation so that we can think along with you about the possibilities to realize your webshop. Do you already have an existing webshop but would you like to give your webshop a new and fresh look? <span className="primary-color">boumedia</span> can also be of service to you here.</p>

                            <Link to="/contact" className="content-link">
                                <span>Please feel free to contact us</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--3">
                        <div className="content">
                            <h1>Are you interested in one of our services for your company?</h1>
                            <h2>We happily come and meet you or arrange a virtual meeting to discuss the opportunities.</h2>

                            <Link to="/contact" className="content-link">
                                <span>Contact us</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--1" id="web-applications">
                        <div className="content">
                            <h1>Web Applications</h1>

                            <p>Each employee has his or her own tasks within the company. As an entrepreneur you are too busy on a daily basis to grow the business. That is why we understand that you do not have the time to analyze all business processes in your company and see where it can be optimized.</p>

                            <p>We at <span className="primary-color">boumedia</span> specialize in analyzing your business processes and in collaboration with the personnel in your company we can identify which processes can be automated by means of a web application. By implementing a web application, daily tasks can be performed much more efficiently and manual tasks can be digitized. Statistics and graphs can also help you grow your business and make certain decisions easier.</p>

                            <p>If you are interested, we can perform a obligation free analysis and come up with a proposal.</p>

                            <Link to="/contact" className="content-link">
                                <span>Please feel free to contact us</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>

                    <div className="row row--2" id="online-advertising">
                        <div className="content">
                            <h1>Online Advertising</h1>

                            <p>In the current digital age there are various options for displaying your products or services to the desired target group. We would be happy to show you the various possibilities, without obligation, of how we can broaden your digital presence.</p>

                            <p>Some examples to broaden your digital presence are social media marketing, Google advertising, search results optimization and email marketing. With a concrete digital strategy we can reach potential customers but also current customers and bring your products and / or services to the forefront of the right target group.</p>

                            <Link to="/contact" className="content-link">
                                <span>Please feel free to contact us</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>
                    </div>
                </StyledOurServices>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default OurServicesPage;